body {
	background-color: white;
	color: $wsw-font-color;
	font-size: $wsw-font-size;
}

ul {
	list-style-type: square;
	padding-left: 15px;
}

p {
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.searchButton {
	display: none;
}
#content {
	> header {
		padding: 5px 0;
		color: $wsw-font-color;
		background-color: white;
	}
	> header.u1gray {
		background-color: lighten($wsw-lightgray, 40%);
	}
	> header.u1darkgray {
		background-color: lighten($wsw-lightgray, 5%);
		color: white;
	}
	> header.u1red {
		background-color: lighten($wsw-blue, 0%);
		color: white;
	}
	> header.u1pink {
		background-color: lighten($wsw-blue, 35%);
	}
	> header.u1white {
		background-color: white;
	}
}

#content-core {
	background-color: white;
	margin: 15px 0;
	padding: 0;
}

.hiddenStructure {
	display: none !important;
}

article {
	.documentDescription {
		font-size: 120%;
		padding: 0.5em 0;
	}
	.image-right {
		float: right;
		margin: 0 0 5px 15px;
	}
	.image-left {
		float: left;
		margin: 0 15px 5px 0;
	}
	.image-inline {
		margin: 5px 0 5px 0;
	}
	.entry {
		margin-bottom: 15px;
		border-bottom: 1px solid lighten($wsw-lightblue, 20%);
		img.image-tile {
			float: right;
			margin: 15px 0 15px 15px;
			&.img-responsive {
				max-width: 128px;
			}
		}
		&:nth-child(even) {
			background: transparent; //lighten($wsw-gray, 10%);
		}
		.summary {
			font-size: 22px;
		}
	}
}

.clearfix {
	width: 100%;
	clear: both;
}
img.image-tile {
	display: none; /*zu klein fuer dasa Frontend */
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4 .h4 {
	font-weight: 500;
}

h1,
.h1 {
	font-size: 24px;
	color: $wsw-darkblue;
	text-transform: uppercase;
	margin-top: 30px;
	text-align: center;
}

h1.documentFirstHeading {
	margin: 10px auto;
	text-transform: uppercase;
}

h2,
.h2 {
	font-size: 20px;
	color: $wsw-darkblue;
}

h3,
.h3 {
	font-size: 18px;
	font-weight: 600;
}

h4,
.h4 {
	font-size: 16px;
	color: $wsw-darkblue;
	font-weight: 600;
}

pre {
	background-color: #f3f3f3;
	border: none;
}

a {
	color: $wsw-darkblue;
	text-decoration: none;
}

a:visited {
	color: $wsw-darkblue;
}

.bg-red {
	background-color: $wsw-darkblue;
	color: white;
}

.bg-gray {
	background-color: $wsw-lightgray;
	color: white;
}

blockquote {
	background-color: lighten($wsw-lightgray, 45%);
	font-style: italic;
	border: none;
}

.col-tile {
	margin: 10px 15px 0 -15px;
}

.icon {
	background: url("/++theme++wsw-theme/img/pdf-icon.png") left center no-repeat;
	padding-left: 26px;
}

.pdf-icon {
	background: url("/++theme++wsw-theme/img/pdf-icon.png") left center no-repeat;
	padding-left: 26px;
}

.png-icon {
	background: url("/++theme++wsw-theme/img/pdf-icon.png") left center no-repeat;
	padding-left: 26px;
}

.jpg-icon {
	background: url("/++theme++wsw-theme/img/pdf-icon.png") left center no-repeat;
	padding-left: 26px;
}

.jpeg-icon {
	background: url("/++theme++wsw-theme/img/pdf-icon.png") left center no-repeat;
	padding-left: 26px;
}

.ppt-icon {
	background: url("/++theme++wsw-theme/img/ppt-icon.png") left center no-repeat;
	padding-left: 26px;
}

.xls-icon {
	background: url("/++theme++wsw-theme/img/xls-icon.png") left center no-repeat;
	padding-left: 26px;
}

.doc-icon {
	background: url("/++theme++wsw-theme/img/doc-icon.png") left center no-repeat;
	padding-left: 26px;
}

.pdf-icon-white {
	background: url("/++theme++wsw-theme/img/pdf-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.png-icon-white {
	background: url("/++theme++wsw-theme/img/pdf-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.jpg-icon-white {
	background: url("/++theme++wsw-theme/img/pdf-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.jpeg-icon-white {
	background: url("/++theme++wsw-theme/img/pdf-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.ppt-icon-white {
	background: url("/++theme++wsw-theme/img/ppt-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.xls-icon-white {
	background: url("/++theme++wsw-theme/img/xls-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.doc-icon-white {
	background: url("/++theme++wsw-theme/img/doc-icon-white.png") left center no-repeat;
	padding-left: 26px;
}

.pdf-icon-black {
	background: url("/++theme++wsw-theme/img/pdf-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.png-icon-black {
	background: url("/++theme++wsw-theme/img/pdf-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.jpg-icon-black {
	background: url("/++theme++wsw-theme/img/pdf-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.jpeg-icon-black {
	background: url("/++theme++wsw-theme/img/pdf-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.ppt-icon-black {
	background: url("/++theme++wsw-theme/img/ppt-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.xls-icon-black {
	background: url("/++theme++wsw-theme/img/xls-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.doc-icon-black {
	background: url("/++theme++wsw-theme/img/doc-icon-black.png") left center no-repeat;
	padding-left: 26px;
}

.bar {
	background: url("/++theme++wsw-theme/img/bar.png") center bottom repeat-x;
}

.btn-wsw {
	font-size: 15px;
	font-weight: bold;
	padding: 18px 15px;
	width: 100%;
	border-radius: 2px;
	font-weight: bold;
	white-space: normal !important;

	&.wsw-darkblue {
		color: white;
		background-color: $wsw-darkblue;
		&:hover {
			background-color: lighten($wsw-darkblue, 10%);
		}
	}
	&.wsw-blue {
		color: white;
		background-color: $wsw-blue;
		&:hover {
			background-color: lighten($wsw-blue, 10%);
		}
	}
	&.wsw-lightblue {
		color: white;
		background-color: $wsw-lightblue;
		&:hover {
			background-color: lighten($wsw-lightblue, 10%);
		}
	}
	&.wsw-darkgray {
		color: white;
		background-color: $wsw-darkgray;
		&:hover {
			background-color: lighten($wsw-darkgray, 10%);
		}
	}
	&.wsw-gray {
		color: white;
		background-color: $wsw-gray;
		&:hover {
			background-color: darken($wsw-gray, 10%);
		}
	}
	&.wsw-lightgray {
		color: $wsw-darkblue;
		background-color: $wsw-lightgray;
		&:hover {
			background-color: darken($wsw-lightgray, 10%);
		}
	}
}

/* --- NEUES DESIGN -------------------- */

.justify-logo {
	justify-content: center;
}
#portal-logo {
	> img {
		max-width: 300px;
	}
}
#portal-footer {
	a {
		color: white;
	}
}
.navbar-barceloneta {
	background-color: transparent;
}

.navbar-nav {
	text-transform: uppercase;
	font-size: 110%;
	//margin-left: -10px;
	margin: auto;
	li {
		background-color: transparent;
		a {
			background-color: transparent;
			color: $wsw-font-color;
			&:hover,
			&:focus {
				color: $wsw-darkblue;
				background-color: transparent;
			}
		}
	}
	li.active > a,
	li.current > a,
	li.inPath > a,
	li.selected > a {
		color: $wsw-darkblue;
		background-color: transparent;
	}
	li.active > a:focus,
	li.active > a:hover,
	li.current > a:focus,
	li.current > a:hover,
	li.inPath > a:focus,
	li.inPath > a:hover,
	li.selected > a:focus,
	li.selected > a:hover {
		color: $wsw-darkblue;
		background-color: transparent;
	}
}

.nav-link {
	color: $wsw-darkgray;
	background-color: transparent;
	&:focus,
	&:hover {
		color: $wsw-darkblue;
		background-color: transparent;
	}
}

.nav-link:focus,
.nav-link:hover {
	color: var(--bs-nav-link-hover-color);
}
.navbar-nav li > a {
	background-color: transparent;
}

.wsw-line {
	background-color: $wsw-darkblue;
	.container {
		.row {
			background: transparent url("/wsw/++theme++wsw-theme/static/img/wsw-line-1920.png") 10px 0 no-repeat;
			height: 10px;
		}
	}
}

#teaserimage {
	margin-top: 1px;
}
#portal-footer-wrapper {
	text-align: left;
}

.navbar .offcanvas {
	--bs-offcanvas-bg: white;
}

#socialmedia {
	text-align: right;
	ul {
		padding-left: 15px;
		list-style: none;
		display: block;
		li {
			padding: 3px 5px;
			display: inline;
		}
	}
}

body.template-persons {
	.department {
		.employees {
			.employee {
				margin: 20px 0 30px 0;
				padding: 0;
				img {
					width: 100%;

					margin-bottom: 20px;
				}
				.name {
					color: $wsw-blue;
				}
				.position {
					font-weight: bold;
					padding-bottom: 0.5em;
				}
				.profession {
				}
				.phone {
				}
				.email {
				}
			}
			.employee-only-name {
				
			}
		}
	}
}

/* --- neu --- */
body.template-full_view {
	#content {
		header {
			display: none;
			text-align: center;
			background-color: white;
			padding: 30px 20px;
		}
		#content-core {
			.intro {
				.h1-accord-background {
					display: none;
				}
				h1 {
					display: none;

				}
			}
			.accord {
				margin: 20px auto;
				//height: 80px;
				.h1-accord-background {
					height: 80px;
					width: 100%;
					position: relative;
					top: 0px;
					left: 0;
					z-index: 0;
					background-size: cover;
					opacity: .6;
					filter: brightness(.5);
				}
				h1 {
					margin-top: -80px;
					text-align: center;
					position: relative;
					z-index: 1;
					background-color: lighten($wsw-lightblue, 30%);
					transition: background-color 1s;
					&:hover {
						background-color: transparent;
					}
					> a {
						width: 100%;
						display: block;
						padding: 30px 0px;
						height: 80px;
						background-color: transparent;
					}
				}
				.lead {
					display: none;
				}
				#section-item {
					display: none;
				}
			}
		}
	}
}

//
/* ---- IMPORT RESPONSIVE PATCHES ----- */
/*
#content-header,
.wsw-line,
#mainnavigation-wrapper,
.teaserimage {
  transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
}
		*/
body.scrolled_down {
	#socialmedia {
		position: fixed;
		top: 10px;
		z-index: 200000;
	}
	#content-header {
		z-index: 100;
		position: fixed;
		left: 50%;
		-ms-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color: white;

		//margin: auto;
		#portal-logo-wrapper {
			//justify-content: left;
			#portal-logo {
				> img {
					height: 40px;
				}
			}
		}
	}
	#mainnavigation-wrapper {
		z-index: 100;
		position: fixed;
		left: 50%;
		-ms-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color: white;
		top: 55px;
		width: 100%;
		height: 45px;
	}
	.wsw-line {
		z-index: 100;
		position: fixed;
		left: 50%;
		-ms-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color: white;
		top: 95px;
		width: 100%;
		background-color: $wsw-darkblue;
	}
	.teaserimage {
		margin-top: 207px;
	}
}
// -----


@media (min-width: 992px) {
	body.section-leistungen.template-full_view {
		#teaserimage {
			margin: 1px 15% auto;
			img {
				width: 70%;
			}
		}
	}
}