$wsw-lightgray: #dcdcdc;
$wsw-gray: #d0d0d0;
$wsw-darkgray: #c3c3c3;

$wsw-lightblue: #8f97b8;
$wsw-blue: #4d5d88;
$wsw-darkblue: #183360;

$wsw-red: #df4343;

$primary: $wsw-darkblue;
$secondary: $wsw-blue;
$tertiary: $wsw-lightblue;

$wsw-background-color: #f0f1f2;
/* $wsw-font-size: 138%; */
$wsw-font-size: 100%;
$wsw-font-color: darken($wsw-darkgray, 45%);

/* $wsw-font-family:  "Trebuchet MS", Helvetica, sans-serif; */
// $google-font: "Source Sans Pro";
// $wsw-font-family: "${google-font}", Helvetica, sans-serif;

$wsw-bg-nav: white; //lighten($wsw-lightblue, 30%);
$wsw-bg-breadcrumb: $wsw-lightgray;
$wsw-teaser: darken($wsw-darkgray, 25%);
