/* ------- footer --------------------------- */

#footer-wsw-line {
    border-bottom: 1px solid white;
    > .container > .row {
        background: transparent url("/wsw/++theme++wsw-theme/static/img/wsw-line-1920.png") 10px 0 no-repeat;
        height: 10px;
    }

}
#portal-footer-wrapper {
    background-color: lighten($wsw-gray, 10%);
    padding: 0;
    #portal-footer {
        
        padding: 40px 0 40px 0;
        color: $wsw-darkblue !important;
        a {
            color: lighten($wsw-darkblue, 0%) !important;
        }
    }

  }
  