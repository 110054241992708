#deco {
  &.deco-bg {
    
  }
  .wsw-line {
    background-color: $wsw-darkblue;
    margin-bottom: 1px;
    > .container > .row {
      background: transparent url("/++theme++wsw-theme/img/wsw-line-1920_20.png") 15px 0 no-repeat;
      padding-top: 10px;
      /* #LM */
    }
  }
  .teaser,
  .teaser-background {
    position: absolute;
    padding: 30px 30px 30px 30px;
    width: 480px;
    float: right;
    margin: -100px -20px 0 0
  }
  .teaser {
    font-size: 18px;
    color: $wsw-blue;
    text-align: left;
    h3 {
      text-transform: uppercase;
      color: $wsw-blue;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.3em;
      margin-top: 0;
    }
  }
  .teaser-background {
    height: 210px;
    background: rgba(255, 255, 255, 0.75);
    text-align: left;
  }
  .btn-action {
    background-color: $wsw-blue;
    color: white;
    &:hover {
      background-color: lighten($wsw-blue, 10%);
    }
    a {
      color: white;
      font-size: 20px;
    }
  }
  .carousel-inner .item .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0px;
    left: 0;
    right: 0;
  }
  .carousel-control.right,
  .carousel-control.left {
    background-image: none;
  }

  .carousel .item {
    min-height: 425px;
    min-height: 300px;
    /* #LM 02.07*/
    height: 100%;
    width: 100%;
  }
  .carousel .slide {
    &.gray-filter {
      filter: grayscale(0%);
    }
  }
  .carousel-control {
    width: 6%;
    text-shadow: none;
  }
  .carousel-indicators {
    bottom: 0;
    li {
      width: 14px;
      height: 14px;
      background-color: rgba(255, 255, 255, .4);
      border: none;
    }
    .active {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: none;
    }
  }
}